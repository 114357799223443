import ProgramingLang from "./Languages";
import Info from "./info";
import './About.css'

function About(){
    return(
    <div className="about" >
       <div>
        <h1>About</h1>
       </div>
       <div className="displaying-flex">
        <Info />
        <ProgramingLang />
       </div>
    </div>)
}
export default About;