function Info(){
    return(
        <div className="personal-info"
        data-aos="zoom-in"
        >
            <p>
                I'm Calvin Arieri, a junior full-stack developer with over 2 years of experience building and maintaining web applications. I'm currently studying at Jomo Kenyatta University of Agriculture and Technology (JKUAT).
                I'm passionate about software development and have a strong understanding of both front-end and back-end technologies. I'm also a skilled freelancer and am always open to new job opportunities, internships, or volunteer roles.
                I have done various projects displayed in my <a href="https://github.com/calvin-arieri" target="_blank">GitHub</a>     
            </p>
        </div>
    )
}
export default Info;