// import { ContactUs } from "./contactForm"
import SocialMedia from "./Socialmedia"
import './footer.css'

function Footer(){
    return(
    <footer id="contact"> 
       <SocialMedia />
    </footer>
    )
}
export default Footer;