import { NavLink } from 'react-router-dom';
import './Nav.css'
import 'animate.css';

function NavBar(){
    
    return(
        <div className="navigation-bar-container">
            <div
            data-aos-ease="linear"
            data-aos-duration="1500"
            data-aos="fade-right"
            >
                <ol>
                    <li className='name-container'> Calvin Arieri</li>
                </ol>
            </div>
            <div>
                <nav>
                    <ol>
                        <li>
                            <a href='#home' >Home</a>
                        </li>
                        <li>
                            <a href='#about'>About</a>
                        </li>
                        <li>
                           <a>Projects</a>
                        </li>
                        <li>
                            <a href="#contact" >Contact</a>
                        </li>
                    </ol>
                </nav>
            </div>         
        </div>
    )
}

export default NavBar;