
import NavBar from "../../components/NavBar/NavBar";
import './blogPage.css'

function BlogPage(){
    return(
        <div>
            <NavBar />
            <code>
                hello world
            </code>
        </div>
    )
}
export default BlogPage;