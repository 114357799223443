
import NavBar from "../../components/NavBar/NavBar";
import Home from "../../components/Home/Home";
import Footer from "../../components/Footer/Footer";
import About from "../../components/About/About";
import { ContactUs } from "../../components/Footer/contactForm";
import './Homepage.css'


export default function HomePage(){  
    return(
        <div className="home-container">
            < NavBar />
            {/* the welcome page */}
            <Home />
            <div className="image"          
            // data-aos="zoom-in"
            // data-aos-duration="2000"
            // data-aos="zoom-out-right"
            >
            <div className="contactMe">
                <h1 className="footerHeading">Get in touch</h1>
                <ContactUs />
            </div>
                {/* <h1            
                >
                    "If it works don't touch it"
                </h1>
                <p>
                    <em>
                        ~first rule of programming~
                    </em>
                </p> */}
                {/* <img src="https://images.unsplash.com/photo-1480506132288-68f7705954bd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1220&q=80" alt="coding" /> */}
            </div>
              <div id="about">
            </div> 
            <About />
            <div id='projects'>
                           
            </div>
                <Footer />
            <div id='space'>
            </div>  
        </div>
        
    )
}