import HomePage from "./pages/homePage/Homepage";
import BlogPage from "./pages/blogPage/blogPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage />} />  
          <Route path="/blog" element={<BlogPage />} />      
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
