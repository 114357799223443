import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    alert('Please wait as the email is being sent')
    emailjs.sendForm('service_u7j64h6', 'template_kqpleu9', form.current, 'nYKK02OSOKIYWneQt')
      .then((result) => {
          console.log(result.text);
          alert('sent successfully')
          e.target.reset()
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail} >
      <label>Name</label>
      <input type="text" name="user_name" required/>
      {/* <label>Phone number</label><br />
      <input type='text' name='phone_number' required/><br /> */}

      <label>Email</label>
      <input type="email" name="user_email" required/>

      {/* <label>Subject:</label><br />
      <input type='text' name='subject' required/><br /> */}

      <label>Message</label>
      <textarea name="message" height='400px' required/>
      <input type="submit" value="Send"/>
    </form>
  );
};