import './Home.css'
import myPic from './IMG_20230924_004605.jpg'

function Home(){
     //let myPic="https://lh3.googleusercontent.com/pw/ADCreHfdhFuFMZU83ztOVCNRiiSyxtxDIzpt5eo7L6wU9GTSljPXoQW1Lgl5EJ1C9pukfHzPP9ljLv7FCspLtus7KHrb0_ZQI2_y7mIHXb7j2PXlVsqg1JVfUTw2w6xsS7AQwz1k3ZcDDcgkl59vZh1i9G2m3atrQc6M9gYpe-FrZDLHaqAt-4GmgPZs3iWe0n1nCBGZWg-iQSlTchbfyjDlyC--U82lZwUM1TNURkGeYeC_HG4cuM8wZ6f_6Ib4AWfNN44DFVlzE7OFtYr5TuUeS0ZQNJ2g8jUzVOQ7uTDYoSPjdOoglYUUHEi8pb2C-5AkZmLNTAFRPzTtEer0q1Sjcvc2OlT0E4vK6z01YagDmWhj_Z1wsAt70ht7tGbAR8DwyoAWTj1-k-VURKJboPoAzWEOH_6hRubG85CKv7-7oPhkp0LLZTlCAS_gHODRBxlI-oAyNQZBsp9avtDvVQgD-OGdIyc_19CAdvE2yBCDKvj9T6LButduAMcjAvEQI50gptZT6SYZKVUjD7YqgmEijtu2-JOdP2esigL-5XD30wwLX0dN2BZklmaRjO-1RzYZ-Guc82hwgfHacwO8OfxNG8RYUOJ0RepcgyeN9ptZ0TF25095seWZPtaveaZSjpnc0VHRZ4nUTWeMDMDALUA3CLe134acbmUQ0ncpr1S8o70MCqhNqOF1F_7OOt_-f-KmAPLCyGgd1IA8EUYsQCXTDYKq56JlD0As7E5PiAuCzHgZgcGk5_IdCs_K1y_41jCJsSSUhANoCSNhFm6qK8Dj-F2Z93mYM8ErYJiG3oTQ0P9jmvHeZszuXcqNEKkNBLSYSZC2rxKC-rn0_C1HTKYECIYHheFbWORCF9F9nlMdIkzfLqWvZyVuNhOYA_0LSEFiHtoPLKvD-rz2aZwl6uf7IQ=w1155-h934-s-no?authuser=0"
    return(
    <div className='landing-details'>
        <div className="moreInfo"
        data-aos="fade-down" 
        // data-aos-anchor-placement="bottom-top" 
        data-aos-duration="1000"
        >
            <h1 className='top-heading'>Hi, </h1>
            <h2 className="add-color">I am Calvin Arieri.</h2>
            <h3 className="add-color">Full-stack web developer</h3>
            <h4 className="add-color">Based in kenya</h4>
        </div>
        <div>
            <img src={myPic} alt='me' 
            data-aos="fade-up"
            data-aos-duration="1000"
            // data-aos-anchor-placement="bottom-top"
            />
        </div>
    </div>
    )
}

export default Home;